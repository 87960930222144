import * as React from "react";

import * as Router from "react-router-dom";

const HomePage = React.lazy(() => import("../screens/HomePage/HomePage"/*webpackChunkName: "Home"*/));
const ContactUs = React.lazy(() => import("../screens/ContactUs/ContactUs"/*webpackChunkName: "Contact us"*/));
const Notes = React.lazy(() => import("../screens/Notes/Notes"/*webpackChunkName: "Notes"*/));
const Jobs = React.lazy(() => import("../screens/Jobs/Jobs"/*webpackChunkName: "Jobs"*/));

const router = () => {
    return (
        <Router.Routes>
            <Router.Route
                path={"/"}
                element={
                    <React.Suspense fallback={<>...</>}>
                        <HomePage />
                    </React.Suspense>
                }
            />
            <Router.Route
                path={"/contactus"}
                element={
                    <React.Suspense fallback={<>...</>}>
                        <ContactUs />
                    </React.Suspense>
                }
            />
            <Router.Route
                path={"/notes"}
                element={
                    <React.Suspense fallback={<>...</>}>
                        <Notes />
                    </React.Suspense>
                }
            />
            <Router.Route
                path={"/jobs"}
                element={
                    <React.Suspense fallback={<>...</>}>
                        <Jobs />
                    </React.Suspense>
                }
            />
        </Router.Routes>
    );
};

export default router;
