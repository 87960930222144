import React from "react";
import { GlobalStyles } from "@mui/material";
import ReactDOM from "react-dom";
import App from "./App";
import globalStyles from "./helpers/globalStyles";

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyles styles={globalStyles} />
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
