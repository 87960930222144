import { keyframes } from "@emotion/react";

const slidRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slidLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const globalStyles = {
  ".scrollAnime": {
    opacity: 0,
  },
  ".scroll-right": {
    animation: `${slidRight} 700ms ease-in-out both`,
  },
  ".scroll-left": {
    animation: `${slidLeft} 700ms ease-in-out both`,
  },
  ".fade": {
    animation: `${fade} 600ms ease-in-out both`,
  },
  ".bg-cover": {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  ".bg-contain": {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  ".centralize": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default globalStyles;
