import { createTheme } from "@mui/material/styles";
import raviWoff from "../assets/fonts/RaviRegular.woff";
import raviBoldWoff from "../assets/fonts/RaviBold.woff";
import ravi900Woff from "../assets/fonts/Ravi900.woff";
import raviEot from "../assets/fonts/RaviRegular.eot";
import raviBoldEot from "../assets/fonts/RaviBold.eot";
import ravi900Eot from "../assets/fonts/Ravi900.eot";
import raviTtf from "../assets/fonts/RaviRegular.ttf";
import raviBoldTtf from "../assets/fonts/RaviBold.ttf";
import ravi900Ttf from "../assets/fonts/Ravi900.ttf";

const theme = createTheme({
    typography: {
        fontFamily: "Ravi, Arial",
    },
    spacing: 10,
    palette: {
        black: {
            main: "#000000",
            "06": "#060606",
            16: "#161616",
            "1c": "#1c1c1c",
        },
        gray: {
            dark: "#4a4a4a",
            main: "#afafaf",
            light: "#e8ecf1",
        },
        white: "#ffffff",
        blue: "#3e64ff",
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: `
                    @font-face {
                      font-family: 'Ravi';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 400;
                      src:local(''),url(${raviEot}) format('embedded-opentype'), url(${raviTtf}) format('truetype') , url(${raviWoff}) format('woff2');
                      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                    }
                    @font-face {
                      font-family: 'Ravi';
                      font-style: bold;
                      font-display: swap;
                      font-weight: 700;
                      src:local(''),url(${raviBoldEot}) format('embedded-opentype'), url(${raviBoldTtf}) format('truetype') , url(${raviBoldWoff}) format('woff2');
                      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                    }
                    @font-face {
                      font-family: 'Ravi';
                      font-style: bold;
                      font-display: swap;
                      font-weight: 900;
                      src:local(''),url(${ravi900Eot}) format('embedded-opentype'), url(${ravi900Ttf}) format('truetype') , url(${ravi900Woff}) format('woff2');
                      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                    }
                  `,
        },
    },
});

export default theme;
